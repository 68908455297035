<template>
  <main class="page">
    <div class="page__grid">
      <ProgressBar :percent="62.5" class="progress--header"></ProgressBar>

      <h1 class="page__header">My Regrets</h1>

      <p class="page__description">
        We have all made some mistakes in life and often we have a strong desire
        that those who come after us learn from such mistakes. In this step
        select the various categories that appear most relevant, use the see
        more + sign to see more options and select your options.
      </p>

      <div class="step">
        <p class="step__text">Check all that apply</p>
        <div class="step__input">
          <div
            class="step__fields"
            v-for="(options, index) in regrets"
            :key="index"
          >
            <label class="step__fields__label">{{ deSlug(index) }}</label>
            <div class="field" v-for="(option, i) in options" :key="i">
              <div class="field__wrap field__wrap-checkbox">
                <div>
                  <input
                    class="field__checkbox"
                    type="radio"
                    :id="`${index}${i}`"
                    :name="`${index}`"
                    :value="option"
                    autocomplete="false"
                    v-model="regretsSelected[index]"
                    required
                  />
                  <label :for="`${index}${i}`"><span></span></label>
                </div>
                <label :for="`${index}${i}`">{{ deSlug(option) }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="step__btn">
          <router-link :to="{ name: 'Images' }" class="button button--secondary"
            >Go Back</router-link
          >
          <button @click="routeToNextPage" class="button button--primary">
            Continue
          </button>
        </div>
      </div>
    </div>
    <div class="page__grid">
      <img
        src="@/assets/img/regrets.svg"
        class="page__grid--image"
        alt="Legacy Composer Home"
      />
    </div>
  </main>
</template>

<script>
import storage from '@/utils/storage'
import ProgressBar from '@/components/ui/progress-bar'
import { deSlug } from '@/utils/convert'

export default {
  name: 'Regrets',
  components: { ProgressBar },
  data() {
    return {
      regretsSelected: {
        wasted_time_and_opportunities: '',
        emphasizing_criticism_over_praise: '',
        failure_to_adequately_provide: '',
        poor_marital_relationships: '',
        failure_to_teach_important_lessons: '',
        not_taking_care_of_own_health: '',
        hesitation_and_procrastination: '',
      },
      regrets: {
        wasted_time_and_opportunities: [
          'failure_to_use_time_together_properly',
          'failure_to_create_more_beautiful_moments_together',
          'placing_work_over_family_time',
        ],
        emphasizing_criticism_over_praise: [
          'excessive_criticism_and_control',
          'unfairly_comparing_children_to_others',
          'setting_impossible_standards',
        ],
        failure_to_adequately_provide: [
          'financially',
          'leadership_and_direction',
          'affection',
        ],
        poor_marital_relationships: [
          'the_conflict_filled_home',
          'lack_of_rhythm_and_process',
          'choosing_outsiders_over_family',
        ],
        failure_to_teach_important_lessons: [
          'money_and_investments',
          'communicating_family_history_and_culture',
          'resilience_and_strength',
        ],
        not_taking_care_of_own_health: [
          'not_adopting_a_healthy_lifestyle',
          'overextending_the_body',
          'addictions',
        ],
        hesitation_and_procrastination: [
          'spending_time_with_loved_ones',
          'pursuing_financial_opportunities',
          'chasing_personal_dreams',
        ],
      },
    }
  },
  created() {
    let storedData = storage.getDataFromLocalStorage('regrets')
    if (storedData) {
      this.regretsSelected = storedData
    }
  },
  methods: {
    routeToNextPage() {
      storage.storeDataToLocalStorage('regrets', this.regretsSelected)
      this.$router.push({ name: 'Blessings' })
    },

    deSlug(text) {
      return deSlug(text)
    },
  },
  computed: {
    validateInput() {
      for (let i in this.regretsSelected) {
        if (this.regretsSelected[i] === '') {
          return false
        }
      }
      return true
    },
  },
}
</script>
